html,
body,
#root,
.App,
.Main {
  max-width: 100%;
  width: 100%;
  overflow-x: hidden; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

hr.horizontalRule {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-top: 2px solid rgba(0, 0, 0, 0.1); }

#root {
  background-color: #fffcfd; }

.Main {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  min-height: 100vh; }

.ClickHereButton button,
.primaryButton {
  color: #e10001;
  border-color: #e10001; }
  .ClickHereButton button:hover, .ClickHereButton button:focus, .ClickHereButton button:active,
  .primaryButton:hover,
  .primaryButton:focus,
  .primaryButton:active {
    color: #fff !important;
    background-color: #ff1516 !important;
    border-color: #ff1516 !important; }
  .ClickHereButton button:focus,
  .primaryButton:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 0, 1, 0.5) !important; }

.Content {
  padding-top: 90px; }
